
import { defineComponent, PropType } from 'vue'
import { IFilterGroup } from '@tokyojob/frontend-components'
import { ISelectedFilterMultiOptions } from '@tokyojob/frontend-components'
import { ISelectedVacancyFilters } from './interface/i-selected-vacancy-filters'
import CFilterMultiOption from './c-filter-types/c-filter-multi-option/c-filter-multi-option.component.vue'
import CFilterSearch from './c-filter-types/c-filter-search/c-filter-search.component.vue'

export default defineComponent({
  /**
   * \These components are named in the filter properties and then consumed. Do not delete.
   */
  components: { CFilterSearch, CFilterMultiOption },
  props: {
    value: {
      type: Object as PropType<Record<string, ISelectedVacancyFilters>>,
      required: true
    },
    filters: {
      type: Object as PropType<IFilterGroup>,
      required: true
    },
    popoutDictionary: {
      required: true,
      type: Object as PropType<Record<string, boolean>>
    }
  },
  setup(props, { emit }) {
    /**
     * Once a singular item has been selected the futh path string is emitted to the parent
     */
    function emitSelectedItem(path: string, category: string): void {
      emit('select-item', path, category)
    }

    function togglePopout(filter: string, status: boolean): void {
      emit('togglePopout', filter, status)
    }

    /**
     * Emits the filter value back up to the parent.
     */
    function emitselectedMultiFilters(newFilter: ISelectedFilterMultiOptions): void {
      emit('multiFilterApplied', newFilter)
    }

    return {
      emitSelectedItem,
      togglePopout,
      emitselectedMultiFilters
    }
  }
})
