
import { defineComponent, PropType, Ref, ref, watch } from 'vue'
import { ButtonFactory, CButton, getLocalization } from '@tokyojob/frontend-components'
import { CFilterMainButton } from '@tokyojob/frontend-components'
import { CFilterPopout } from '@tokyojob/frontend-components'
import { IFilterMultiOption } from '@tokyojob/frontend-components'
import { ISelectedFilterMultiOptions } from '@tokyojob/frontend-components'
import { ContractTypeButtonFactory } from '@tokyojob/frontend-components'
import { getProvidedApp, getRouter } from '~/base/utils/provided-getter'

const contractTypeButtonFactory = new ContractTypeButtonFactory()

export default defineComponent({
  components: { CFilterMainButton, CButton, CFilterPopout },
  props: {
    value: {
      type: Object as PropType<ISelectedFilterMultiOptions>,
      required: true
    },
    /**
     * Filter props required for template/logic.
     */
    filter: {
      type: Object as PropType<IFilterMultiOption>,
      required: true
    },
    /**
     * Whether the filter window is being displayed or not.
     */
    popoutIsOpen: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const App = getProvidedApp()
    const router = getRouter()
    const localization = getLocalization(App)
    const filterMainButton: Ref<Vue> = ref(null) as any

    const radioButtons = contractTypeButtonFactory.makeContractTypeRadioButtons(localization)
    const applyButton = ButtonFactory.applyButton({ width: '120px' })
    const newValue = ref<ISelectedFilterMultiOptions>(props.value)

    // TODO: Don't update the property of the props
    props.filter.data = newValue

    /**
     * Emit instruction to open/close popout. Closes all other popouts if any are open
     */
    function togglePopout(): void {
      emit('togglePopout', props.filter.name, !props.popoutIsOpen)
      const btn = filterMainButton.value
      btn.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }

    /**
     * Emits the filter value back up to the parent.
     */
    function applyFilter(value: ISelectedFilterMultiOptions): void {
      emit('multiFilterApplied', value)

      // TODO: Don't update the property of the props
      props.filter.data = value

      newValue.value = value
      emit('input', newValue.value)
      togglePopout()
    }

    watch(
      () => props.value,
      (value) => {
        newValue.value = value

        // TODO: Don't update the property of the props
        props.filter.data = value
      }
    )

    return {
      // component reference
      filterMainButton,
      App: App,
      router: router,

      // data properties
      radioButtons,
      applyButton,
      newValue,

      // methods
      togglePopout,
      applyFilter
    }
  }
})
